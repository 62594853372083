import React from 'react'
import { Link } from 'gatsby'
import { Layout, Helmet, Section, Container } from 'components'

const NotFoundPage = () => (
    <Layout className="notFoundPage">
        <Helmet
            title='Nomade, gestion immobilière'
            description='Notre mission, assurer la qualité de vie de nos locataires, dans nos immeubles, en offrant des espaces locatifs novateurs et un service-client exemplaire.'
            url='/not-found'
        />
        <Section>
            <Container>
                <div className="notFoundPage-container">
                    <p>Il semblerait que vous vous êtes perdu !</p>
                    <div className="notFoundPage-container--link">
                        <Link to="/">
                            Retour à l'accueil
                    </Link>
                    </div>
                </div>
            </Container>
        </Section>
    </Layout>
)
export default NotFoundPage;
